import {Injectable} from '@angular/core';

import {toDate} from 'date-fns-tz';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import jsonStableStringify from 'json-stable-stringify';
import * as Diff from "diff";
import {Pair} from "../model/masterdata-model";

@Injectable()
export class UtilService {
  public formatDate(date: any): string {
    if (date) {
      return format(toDate(date), 'dd-MM-yyyy');
    }
    return '';
  }

  public isExpired(date: string): boolean {
    return isBefore(toDate(date), new Date());
  }

  public isPairDifferent(pair: Pair<any, any> | undefined): boolean {
    const first = {...pair?.first}
    const second = {...pair?.second}
    first.id = second.id

    const newText = first ? jsonStableStringify(first, {space: '  '}) : '';
    const oldText = second ? jsonStableStringify(second, {space: '  '}) : '';
    const x = Diff.diffJson(oldText, newText);
    return !!x.find(value => value.added || value.removed);
  }
}
