import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './components/navigation/navigation.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { VersionComponent } from './components/version/version.component';
import { ProfileService } from './services/auth/profile.service';
import { UtilService } from './services/util.service';
import { FeatureSwitchService } from './services/feature-switch.service';
// Import the module from the SDK
import { AuthHttpInterceptor, AuthModule, HttpMethod } from '@auth0/auth0-angular';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AppBarComponent } from "./components/app-bar/app-bar.component";
import { DiffComponent } from "./components/diff/diff.component";
import { HttpErrorInterceptor } from "./http-error-interceptor";

@NgModule({ declarations: [
        AppComponent,
        NavigationComponent,
        AppBarComponent,
        IconButtonComponent,
        VersionComponent,
        DiffComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MaterialModule,
        DashboardModule,
        ConfirmDialogModule,
        // Import the module into the application, with configuration
        AuthModule.forRoot({
            domain: 'ctgb.eu.auth0.com',
            clientId: 'IW7IsLxngES1mL3Zjq9IwG657A3XbSeB',
            cacheLocation: 'localstorage',
            httpInterceptor: {
                allowedList: [{ uri: 'api/*', allowAnonymous: true, httpMethod: HttpMethod.Get }, `*`],
            },
            authorizationParams: {
                audience: 'https://dtg-manager.ctgb.nl',
                redirect_uri: window.location.origin,
            }
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
        ProfileService,
        UtilService,
        FeatureSwitchService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
