import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private snackbar: MatSnackBar
  ) { }

  public message: string;

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
        
      let msg;
      switch(request.method) {
          case 'PUT': {
            msg = 'Wijzigen'; 
            break; 
          }
          case 'POST': { 
            msg = 'Opslaan';
            break; 
          }
          case 'DELETE': { 
            msg = 'Verwijderen';
            break; 
          }
          default: { 
            msg = 'Ophalen';
            break; 
         } 
        } 
        this.snackbar.open(`${msg} mislukt`, '', {
          duration: 5000,
          panelClass: 'snack-bar-error'
        });
        return throwError(error);
    }));
  }
}
