import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/auth/profile.service';
import { FeatureSwitches, FeatureSwitchService } from '../../services/feature-switch.service';
import {AuthService} from "@auth0/auth0-angular";

interface NavigationItem {
  icon: string;
  label: string;
  route: string;
  testId: string;
  isEnabled: boolean;
  isAllowed: boolean;
  rolesAllowed: string[];
  featureSwitch?: string;
}

interface NavigationSection {
  title?: string;
  items: NavigationItem[];
  isEnabled: boolean;
  isAllowed: boolean;
  rolesAllowed: string[];
  featureSwitch?: string;
}

@Component({
  selector: 'ctgb-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  public auth: AuthService
  public sections: NavigationSection[] = [
    {
      title: undefined,
      items: [
        {
          testId: 'home',
          icon: 'dashboard',
          label: 'Home',
          route: '/',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
      ],
      isEnabled: false, // Do not show in menu is hardcoded
      isAllowed: true,
      rolesAllowed: [],
    },
    {
      title: 'MST Rapportages',
      items: [
        {
          testId: 'staatscourant',
          icon: 'dashboard',
          label: 'Staatscourant',
          route: '/reports/staatscourant',
          isEnabled: true,
          isAllowed: false,
          rolesAllowed: [],
        },
        {
          testId: 'etiketzin',
          icon: 'dashboard',
          label: 'Etiketzinnen Rapport',
          route: '/reports/etiketzin',
          isEnabled: true,
          isAllowed: false,
          rolesAllowed: [],
        },
        {
          testId: 'jaarlijksevergoedingen',
          icon: 'dashboard',
          label: 'Jaarlijkse Vergoedingen',
          route: '/reports/jaarlijksevergoedingen',
          isEnabled: true,
          isAllowed: false,
          rolesAllowed: [],
        },
        {
          testId: 'samenstellingtoelatingen',
          icon: 'dashboard',
          label: 'Samenstelling Toelatingen',
          route: '/reports/samenstellingtoelatingen',
          isEnabled: true,
          isAllowed: false,
          rolesAllowed: [],
        },
        {
          testId: 'samenstellingaanvragen',
          icon: 'dashboard',
          label: 'Samenstelling Aanvragen',
          route: '/reports/samenstellingaanvragen',
          isEnabled: true,
          isAllowed: false,
          rolesAllowed: [],
        },
        {
          testId: 'aanvragen',
          icon: 'dashboard',
          label: 'Aanvragen',
          route: '/reports/aanvragen',
          isEnabled: true,
          isAllowed: false,
          rolesAllowed: [],
        },
        {
          testId: 'toelatingen',
          icon: 'dashboard',
          label: 'Toelatingen',
          route: '/reports/toelatingen',
          isEnabled: true,
          isAllowed: false,
          rolesAllowed: ['mst-admin', 'mdm-admin'],
        }
      ],
      isEnabled: true,
      isAllowed: false,
      rolesAllowed: [],
    },
    {
      title: 'DTG',
      items: [
        {
          testId: 'dtg-list-versions',
          icon: 'dashboard',
          label: 'DTG Lijsten',
          route: '/versions',
          isEnabled: false,
          isAllowed: false,
          rolesAllowed: ['mst-admin', 'mdm-admin'],
          featureSwitch: FeatureSwitches.FEATURE_DTG_ENABLED,
        },
      ],
      isEnabled: false,
      isAllowed: false,
      rolesAllowed: ['mst-admin', 'mdm-admin'],
      featureSwitch: FeatureSwitches.FEATURE_DTG_ENABLED,
    },
    {
      title: 'Masterdata Algemeen',
      items: [
        {
          testId: 'applicationtypes',
          icon: 'dashboard',
          label: 'Aanvraagtypes',
          route: '/masterdata/applicationtypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'decisiontypes',
          icon: 'dashboard',
          label: 'Besluittypes',
          route: '/masterdata/decisiontypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'concentrationunits',
          icon: 'dashboard',
          label: 'Concentratieeenheden',
          route: '/masterdata/concentrationunits',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'packagingunits',
          icon: 'dashboard',
          label: 'Verpakkingseenheden',
          route: '/masterdata/packagingunits',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'doseunits',
          icon: 'dashboard',
          label: 'Doseringseenheden',
          route: '/masterdata/doseunits',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'formulationtypes',
          icon: 'dashboard',
          label: 'Formuleringstypes',
          route: '/masterdata/formulationtypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'functions',
          icon: 'dashboard',
          label: 'Functies',
          route: '/masterdata/functions',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'symbolcodes',
          icon: 'dashboard',
          label: 'Symboolcodes',
          route: '/masterdata/symbolcodes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'labeltexts',
          icon: 'dashboard',
          label: 'Etiketzinnen',
          route: '/masterdata/labeltexts',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'materialtypes',
          icon: 'dashboard',
          label: 'Materiaaltypes',
          route: '/masterdata/materialtypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'productcategories',
          icon: 'dashboard',
          label: 'Middelcategorien',
          route: '/masterdata/productcategories',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'substances',
          icon: 'dashboard',
          label: 'Stoffen',
          route: '/masterdata/substances',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'packagingtypes',
          icon: 'dashboard',
          label: 'Verpakkingtypes',
          route: '/masterdata/packagingtypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'countries',
          icon: 'dashboard',
          label: 'Landen',
          route: '/masterdata/countries',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'restrictionSentence',
          icon: 'dashboard',
          label: 'Restrictiezinnen',
          route: '/masterdata/restrictions',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'metadata',
          icon: 'dashboard',
          label: 'Metadata',
          route: '/masterdata/metadata',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
      ],
      isEnabled: true,
      isAllowed: true,
      rolesAllowed: [],
    },
    {
      title: 'Masterdata Biociden',
      items: [
        {
          testId: 'biocide_producttype',
          icon: 'dashboard',
          label: 'Productsoort',
          route: '/masterdata/biocide/producttypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'biocide_applicationmethod',
          icon: 'dashboard',
          label: 'Toepassingmethodes Biocide',
          route: '/masterdata/biocide/applicationmethods',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'biocide_trivialorganismname',
          icon: 'dashboard',
          label: 'Triviale organisme namen',
          route: '/masterdata/biocide/trivialnames',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'biocide_scientificorganismname',
          icon: 'dashboard',
          label: 'Wetenschappelijke organisme namen',
          route: '/masterdata/biocide/scientificnames',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'biocide_developmentstage',
          icon: 'dashboard',
          label: 'Ontwikkelstadia',
          route: '/masterdata/biocide/developmentstages',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
      ],
      isEnabled: true,
      isAllowed: true,
      rolesAllowed: [],
    },
    {
      title: 'Masterdata Gewas',
      items: [
        {
          testId: 'ppp_applicationmethod',
          icon: 'dashboard',
          label: 'Toepassingmethodes gewas',
          route: '/masterdata/ppp/applicationmethods',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'ppp_targetlocations',
          icon: 'dashboard',
          label: 'Toepassinglocaties gewas',
          route: '/masterdata/ppp/targetlocations',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'outcometype',
          icon: 'dashboard',
          label: 'Aard werking',
          route: '/masterdata/ppp/outcometypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
      ],
      isEnabled: true,
      isAllowed: true,
      rolesAllowed: [],
    },
    {
      title: 'Keuzelijstjes aanvraagtypes',
      items: [
        {
          testId: 'griptemplates',
          icon: 'dashboard',
          label: 'Grip Templates',
          route: '/masterdata/griptemplates',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'actions',
          icon: 'dashboard',
          label: 'Handelingen',
          route: '/masterdata/actions',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'acmtemplates',
          icon: 'dashboard',
          label: 'Acm Templates',
          route: '/masterdata/acmtemplates',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'sharepointtemplates',
          icon: 'dashboard',
          label: 'Sharepoint Templates',
          route: '/masterdata/sharepointtemplates',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'ctgbroles',
          icon: 'dashboard',
          label: 'Rol Ctgb',
          route: '/masterdata/ctgbroles',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'categories',
          icon: 'dashboard',
          label: 'Rapportage Categorieën',
          route: '/masterdata/categories',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'actioncategories',
          icon: 'dashboard',
          label: 'Handeling Categorieën',
          route: '/masterdata/actioncategories',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'productgroups',
          icon: 'dashboard',
          label: 'Product Groepen',
          route: '/masterdata/productgroups',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
      ],
      isEnabled: true,
      isAllowed: true,
      rolesAllowed: [],
    },
    {
      title: 'Statische masterdatatypes',
      items: [
        {
          testId: 'applicationcategories',
          icon: 'dashboard',
          label: 'Applicatie Categorieën',
          route: '/masterdata/applicationcategories',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'authorisationtypes',
          icon: 'dashboard',
          label: 'Toelatingstypes',
          route: '/masterdata/authorisationtypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'categorytypes',
          icon: 'dashboard',
          label: 'Productsoorten',
          route: '/masterdata/categorytypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'decisionresults',
          icon: 'dashboard',
          label: 'Besluit resultaattypes',
          route: '/masterdata/decisionresults',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'decisionstatuses',
          icon: 'dashboard',
          label: 'Besluitstatussen',
          route: '/masterdata/decisionstatuses',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'signalwords',
          icon: 'dashboard',
          label: 'Signaalwoorden',
          route: '/masterdata/signalwords',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'statementtypes',
          icon: 'dashboard',
          label: 'Labeltypes',
          route: '/masterdata/statementtypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'timeunits',
          icon: 'dashboard',
          label: 'Tijdseenheden',
          route: '/masterdata/timeunits',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'usertypes',
          icon: 'dashboard',
          label: 'Gebruikerstypes',
          route: '/masterdata/usertypes',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
        {
          testId: 'fieldsofuse',
          icon: 'dashboard',
          label: 'Toepassingsgebieden',
          route: '/masterdata/fieldsofuse',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
          },
          {
          testId: 'usecategories',
          icon: 'dashboard',
          label: 'Gebruikerscategorieën',
          route: '/masterdata/usecategories',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
          },
      ],
      isEnabled: true,
      isAllowed: true,
      rolesAllowed: [],
    },
    {
      title: 'Overig',
      items: [
        {
          testId: 'otapItems',
          icon: 'dashboard',
          label: 'OTAP Items',
          route: '/masterdata/otapitems',
          isEnabled: true,
          isAllowed: true,
          rolesAllowed: [],
        },
      ],
      isEnabled: true,
      isAllowed: true,
      rolesAllowed: [],
    },
  ];

  private roles: string[] = [];
  private featureSwitches?: FeatureSwitches;

  public constructor(private router: Router, public profileService: ProfileService, public featureSwitchService: FeatureSwitchService) {
    this.profileService.roles.subscribe((roles: string[]) => {
      this.roles = roles;
      this.processRoles();
    });
    this.featureSwitchService.featureSwitches.subscribe((fs: FeatureSwitches) => {
      this.featureSwitches = fs;
      this.processFeatureSwitches();
    });
  }

  public navigate(route: string): void {
    this.router.navigate([route]);
  }

  public getColor(route: string): string {
    if (this.router.url === route) {
      return 'primary';
    }
    return 'textSecondary';
  }

  private processRoles() {
    this.sections.forEach(section => {
      this.isAllowed(section);
      section.items.forEach(item => {
        this.isAllowed(item);
      });
    });
  }
  private processFeatureSwitches() {
    this.sections.forEach(section => {
      this.isEnabled(section);
      section.items.forEach(item => {
        this.isEnabled(item);
      });
    });
  }

  private isEnabled(item: NavigationItem | NavigationSection) {
    if (item.featureSwitch && this.featureSwitches) {
      item.isEnabled = this.featureSwitches.isFeatureEnabled(item.featureSwitch);
    }
  }

  private isAllowed(item: NavigationItem | NavigationSection) {
    const intersection = item.rolesAllowed.filter(item1 => this.roles.some(item2 => item1 === item2));
    item.isAllowed = item.rolesAllowed.length === 0 || intersection.length > 0;
  }
}
